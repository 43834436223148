<template>
	<v-app id="login" class="secondary">
		<v-main>
			<v-container fluid fill-height>
				<v-layout align-center justify-center>
					<v-flex xs12 sm8 md4 lg4>
						<v-card class="elevation-1 pa-3">
							<v-card-text>
								<div class="layout column align-center">
									<!-- <img :src="require(`@/assets/${'logo.svg'}`)" alt="Vue Material Admin" width="70"> -->
									<h1 class="flex my-4 primary--text">Login</h1>
								</div>
								<v-form>
									<v-text-field
										append-icon="person"
										name="login"
										label="Login"
										type="text"
										color="#101928"
										v-model="userEmail"
										:error="error"
										:rules="[rules.required]"/>
									<v-text-field
										:type="hidePassword ? 'password' : 'text'"
										:append-icon="hidePassword ? 'visibility_off' : 'visibility'"
										name="password"
										label="Password"
										id="password"
										color="#101928"
										:rules="[rules.required]"
										v-model="password"
										:error="error"
										@click:append="hidePassword = !hidePassword"/>
								</v-form>
							</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn block dark color="#101928" @click="login" :loading="loading">Login</v-btn>
							</v-card-actions>
						</v-card>
					</v-flex>
				</v-layout>
			</v-container>
			<v-snackbar
				color="red darken-2"
				v-model="showResult"
				:timeout="2000"
				top>
				{{ result }}
			</v-snackbar>
		</v-main>
	</v-app>
</template>

<script>
import CommonApi from '@/services/CommonApi'

export default {
	data() {
		return {
			loading: false,
			userEmail: '',
			password: '',
			hidePassword: true,
			error: false,
			showResult: false,
			result: '',
			rules: {
				required: value => !!value || 'Required.'
			}
		}
	},

	methods: {
		login() {

			if (!this.userEmail || !this.password) {
				this.result = "Please enter your Email and Password.";
				this.showResult = true;
				return;
			}

			let data = { usr : this.userEmail, pwd: this.password };

			CommonApi.post_data('innosoft_salon.api.custom_login', data)
			.then(res => {
				console.log(res)
				if(res.status_code == 200) {
					localStorage.setItem('user-name', res.full_name);
					localStorage.setItem('api-key', res.key_details.api_key);
					localStorage.setItem('api-secret', res.key_details.api_secret);
					this.$router.push('/calendar')
				} else {
					this.error = true;
					this.result = res.message;
					this.showResult = true;
				}
			})
		}
	}
}
</script>

<style scoped lang="css">
	#login {
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		content: "";
		z-index: 0;
	}
	.v-application.secondary {
		background-color: #668091;
	}
</style>
