import axios from 'axios'


export default {

	// base_url: "https://assasiyat.is.sa/api/method/",
	base_url: "http://207.148.116.183/api/method/",
	api_key:'',
	api_secret:'',
	get_data (url, params = '') {
		this.validate_key()
		let requestOptions = {
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': "token " + this.api_key + ":" + this.api_secret
			},
		};
		return axios.get(this.base_url+url+'?'+params,requestOptions)
		.then(response => {
			// console.log(xhr.getResponseHeader('Set-Cookie'))
			// console.log(response)
			if (response.data.status_code == 200){
				return response.data;
			} else {
				return alert(response.data.message);
			}
		})
		.catch(error => {
			if(error.response.status == "403") {
				this.unauthorize_login()
			}
		})
	},

	post_data (url, params = '') {
		let requestOptions = ''
		if(url != "innosoft_salon.api.custom_login"){
			this.validate_key()
			requestOptions = {
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
					'Authorization': "token " + this.api_key + ":" + this.api_secret
				},
			};
		}
		else{
			requestOptions = {
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json'
				},
			}
		}
		return axios.post(this.base_url+url, params, requestOptions )
		.then(response => {
			// console.log(xhr.getResponseHeader('Set-Cookie'))
			// console.log(response)
			if (response.data.status_code == 200){
				return response.data;
			} else {
				return alert(response.data.message);
			}
		})
		.catch(error => {
			if(error.response.status == "403") {
				this.unauthorize_login()
			}
		})
	},
	validate_key(){
		this.api_key = localStorage.getItem('api-key');
		this.api_secret = localStorage.getItem('api-secret');
		if(this.api_secret == '' || this.api_key == ''){
			// localStorage.removeItem("api-key");
			// localStorage.removeItem("api-secret");
			// localStorage.removeItem('user-name');
			// this.$router.push('/login')
			this.unauthorize_login()
		}
	},
	unauthorize_login(){
		localStorage.removeItem("api-key");
		localStorage.removeItem("api-secret");
		localStorage.removeItem('user-name');
		location.reload();
	}
}
